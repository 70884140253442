<template>
  <div class="contact_us">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <AboutUsItem />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import AboutUsItem from '@/components/aboutUs/aboutUsItem'
import LayoutBottom from '@/components/LayoutBottom'
import { getNavData } from '@/utils/navData'
export default {
  components: {
    NavTab,
    AboutUsItem,
    LayoutBottom
  },
  data() {
    return {
      navData: getNavData(1)
    }
  }

}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px){
  .con_center{
    padding-top: 88px;
  }
}
@media only screen and (max-width: 767px){}

</style>
