export function getNavData(index) {
  return {
    index: index,
    list: [
      {
        name: 'navTab.tab1',
        link: '/'
      },
      {
        name: 'navTab.tab2',
        link: '/about_us'
      },
      {
        name: 'navTab.tab3',
        link: '/join_us'
      },
      {
        name: 'navTab.tab4',
        link: '/contact_us'
      }
      // {
      //   name: 'Aviso de Privacidad',
      //   link: '/privacy_statement'
      // }
    ]
  }
}
